import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCoffee as fasCoffee,
  faClipboardList as fasClipboardList,
  faClock as fasClock,
  faWrench as fasWrench,
  faPause as fasPause,
  faCheck as fasCheck,
  faComment as fasComment,
  faRepeat as fasRepeat,
  faUser as fasUser,
  faCogs as fasCogs,
  faBell as fasBell,
  faBriefcase as fasBriefcase,
  faUsers as fasUsers,
  faCalendarAlt as fasCalendarAlt,
  faBoxAlt as fasBoxAlt,
  faBox as fasBox,
  faTag as fasTag,
  faExclamationTriangle as fasExclamationTriangle,
  faList as fasList,
  faFileAlt as fasFileAlt,
  faUserAlt as fasUserAlt,
  faEnvelope as fasEnvelope,
  faFilePdf as fasFilePdf,
  faExclamationCircle as fasExclamationCircle,
  faLock as fasLock,
  faImage as fasImage,
  faListUl as fasListUl,
  faFileInvoiceDollar as fasFileInvoiceDollar,
  faSortAlphaDown as fasSortAlphaDown,
  faArrowAltRight as fasArrowAltRight,
  faArrowAltDown as fasArrowAltDown,
  faArrowAltLeft as fasArrowAltLeft,
  faAngleDown as fasAngleDown,
  faChartBar as fasChartBar,
  faCaretDown as fasCaretDown,
  faEllipsisH as fasEllipsisH,
  faPlus as fasPlus,
  faListAlt as fasListAlt,
  faArchive as fasArchive,
  faTruck as fasTruck,
  faFileCheck as fasFileCheck,
  faWarehouse as fasWarehouse,
  faQrcode as fasQrcode,
  faTimes as fasTimes,
  faFilter as fasFilter,
  faTrashAlt as fasTrashAlt,
  faSort as fasSort,
  faExternalLink as fasExternalLink,
  faFolder as fasFolder,
  faFileImage as fasFileImage,
  faFileWord as fasFileWord,
  faFilePowerpoint as fasFilePowerpoint,
  faFileExcel as fasFileExcel,
  faFileAudio as fasFileAudio,
  faFileVideo as fasFileVideo,
  faFileArchive as fasFileArchive,
  faFileCode as fasFileCode,
  faCog as fasCog,
  faLayerGroup as fasLayerGroup,
  faMobileAlt as fasMobileAlt,
  faEye as fasEye,
  faLockOpenAlt as fasLockOpenAlt,
  faLockAlt as fasLockAlt,
  faFilePlus as fasFilePlus,
  faBrowser as fasBrowser,
  faLink as fasLink,
  faWarehouseAlt as fasWarehouseAlt,
  faInfoCircle as fasInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCoffee as farCoffee,
  faCheck as farCheck,
  faCalendarAlt as farCalendarAlt,
  faExclamationCircle as farExclamationCircle,
  faRepeat as farRepeat,
  faDollarSign as farDollarSign,
  faQuestionCircle as farQuestionCircle,
  faPhone as farPhone,
  faEnvelope as farEnvelope,
  faAngleRight as farAngleRight,
  faAngleDown as farAngleDown,
  faAngleLeft as farAngleLeft,
  faComment as farComment,
  faTruck as farTruck,
  faWrench as farWrench,
  faFolder as farFolder,
  faCogs as farCogs,
  faLayerGroup as farLayerGroup,
  faBox as farBox,
  faBoxAlt as farBoxAlt,
  faFileAlt as farFileAlt,
  faLockOpenAlt as farLockOpenAlt,
  faUserLock as farUserLock,
  faUser as farUser,
  faTrashAlt as farTrashAlt,
  faBan as farBan,
  faPrint as farPrint,
  faPlusCircle as farPlusCircle,
  faClock as farClock,
  faMinus as farMinus,
  faInfoCircle as farInfoCircle,
  faEllipsisH as farEllipsisH,
  faSearch as farSearch,
  faAngleDoubleLeft as farAngleDoubleLeft,
  faAngleDoubleRight as farAngleDoubleRight,
  faBriefcase as farBriefcase,
  faExclamationTriangle as farExclamationTriangle,
  faUpload as farUpload,
  faLink as farLink,
  faPen as farPen,
  faFileImage as farFileImage,
  faDownload as farDownload,
  faExternalLink as farExternalLink,
  faHistory as farHistory,
  faArrowAltRight as farArrowAltRight,
  faArrowAltDown as farArrowAltDown,
  faArrowAltLeft as farArrowAltLeft,
  faImage as farImage,
  faRedoAlt as farRedoAlt,
  faPlus as farPlus,
  faCalendarDay as farCalendarDay,
  faExpand as farExpand,
  faChartBar as farChartBar,
  faQrcode as farQrcode,
  faCog as farCog,
  faInbox as farInbox,
  faArrowCircleRight as farArrowCircleRight,
  faFileCheck as farFileCheck,
  faFilter as farFilter,
  faBrowser as farBrowser,
  faClone as farClone,
  faLayerMinus as farLayerMinus,
  faFileInvoiceDollar as farFileInvoiceDollar,
  faPause as farPause,
  faArchive as farArchive,
  faUsers as farUsers,
  faGlobeEurope as farGlobeEurope,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCoffee as falCoffee,
  faCheck as falCheck,
  faExclamation as falExclamation,
  faAngleRight as falAngleRight,
  faEllipsisH as falEllipsisH,
  faTimes as falTimes,
  faComment as falComment,
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faAngleLeft as falAngleLeft,
} from '@fortawesome/pro-light-svg-icons';

const init = () => {
  library.add(
    fasClipboardList,
    fasCoffee,
    fasClock,
    fasComment,
    fasWrench,
    fasPause,
    fasCheck,
    fasRepeat,
    fasUser,
    fasCogs,
    fasBell,
    fasBriefcase,
    fasUsers,
    fasCalendarAlt,
    fasBoxAlt,
    fasTag,
    fasExclamationTriangle,
    fasList,
    fasFileAlt,
    fasUserAlt,
    fasEnvelope,
    fasFilePdf,
    fasExclamationCircle,
    fasLock,
    fasImage,
    fasListUl,
    fasFileInvoiceDollar,
    fasSortAlphaDown,
    fasArrowAltDown,
    fasArrowAltRight,
    fasAngleDown,
    fasChartBar,
    fasCaretDown,
    fasEllipsisH,
    fasArrowAltLeft,
    fasPlus,
    fasListAlt,
    fasArchive,
    fasTruck,
    fasFileCheck,
    fasBox,
    fasWarehouse,
    fasQrcode,
    fasTimes,
    fasFilter,
    fasTrashAlt,
    fasSort,
    fasExternalLink,
    fasFolder,
    fasFileImage,
    fasFileWord,
    fasFilePowerpoint,
    fasFileExcel,
    fasFileAudio,
    fasFileVideo,
    fasFileArchive,
    fasFileCode,
    fasCog,
    fasLayerGroup,
    fasMobileAlt,
    fasEye,
    fasLockOpenAlt,
    fasLockAlt,
    fasFilePlus,
    fasBrowser,
    fasLink,
    fasWarehouseAlt,
    fasInfoCircle
  );

  library.add(
    farCoffee,
    farCheck,
    farCalendarAlt,
    farExclamationCircle,
    farDollarSign,
    farQuestionCircle,
    farRepeat,
    farPhone,
    farEnvelope,
    farAngleRight,
    farAngleDown,
    farAngleLeft,
    farComment,
    farTruck,
    farWrench,
    farLayerGroup,
    farCogs,
    farBox,
    farBoxAlt,
    farFileAlt,
    farLockOpenAlt,
    farUserLock,
    farTrashAlt,
    farBan,
    farPrint,
    farPlusCircle,
    farClock,
    farMinus,
    farInfoCircle,
    farEllipsisH,
    farSearch,
    farAngleDoubleLeft,
    farAngleDoubleRight,
    farBriefcase,
    farExclamationTriangle,
    farUpload,
    farLink,
    farPen,
    farFileImage,
    farDownload,
    farExternalLink,
    farFolder,
    farHistory,
    farArrowAltDown,
    farArrowAltLeft,
    farArrowAltRight,
    farImage,
    farRedoAlt,
    farPlus,
    farCalendarDay,
    farExpand,
    farChartBar,
    farQrcode,
    farCog,
    farInbox,
    farArrowCircleRight,
    farFileCheck,
    farFilter,
    farBrowser,
    farClone,
    farLayerMinus,
    farFileInvoiceDollar,
    farPause,
    farUser,
    farArchive,
    farUsers,
    farGlobeEurope
  );

  library.add(
    falCoffee,
    falCheck,
    falExclamation,
    falEllipsisH,
    falAngleRight,
    falTimes,
    falComment,
    falAngleUp,
    falAngleDown,
    falAngleLeft
  );
};

export default {
  init,
};
