import React, { Component } from 'react';
import ThisWeekChart from './ThisWeekChart';
import TimelineChart from './TimelineChart';
import LogoSvg from 'assets/images/logo.svg';
import styles from './style.module.scss';

export default class LeftContent extends Component {
  renderTitle = () => (
    <div className={styles['title-container']}>
      <div className={styles['title']}>Arbetsordrar</div>
      <div className={styles['logo']}>
        <img src={LogoSvg} alt="logo" />
      </div>
    </div>
  )

  renderGraphs = () => (
    <div className={styles['graphs-container']}>
      <div>
        <ThisWeekChart dashboard={this.props.dashboard} />
      </div>
      <div>
        <TimelineChart dashboard={this.props.dashboard} />
      </div>
    </div>
  )

  render() {
    return (
      <div className={styles['content']}>
        {this.renderTitle()}
        {this.renderGraphs()}
      </div>
    )
  }
}
