/* eslint-disable import/no-extraneous-dependencies */
/*
  issue with react-hot-loader
  even though those 2 deps are only used in development
  eslint has no way to tell that and outputs an error
*/

// react deps
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "state";
import FontAwesomeIcons from "utilities/FontAwesomeIcons";
import MomentHelper from "utilities/MomentHelper";
import * as serviceWorker from "./serviceWorker";

/*
  Third party css files
*/
import "./utilities/Polyfill";

MomentHelper.init();
FontAwesomeIcons.init();
if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/en");
  require("@formatjs/intl-pluralrules/dist/locale-data/sv");
}
if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/sv");
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
