import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import WorkOrders from './WorkOrders';
import OperationalMaintenances from './OperationalMaintenances';
import HelperFunctions from 'utilities/HelperFunctions';
import styles from './style.module.scss';

class Dashboard extends Component {
  state = {
    token: null,
    loading: true,
    dashboard: {},
    notFound: false,
  }

  intervalId;

  componentDidMount() {
    moment.locale('sv');
    this.initiateDashboard();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.token != this.props.match.params.token) {
      clearInterval(this.intervalId);
      this.initiateDashboard();
    }
  }

  initiateDashboard = () => {
    this.setState({ loading: true, token: this.props.match.params.token }, () => {
      this.fetchDashboard();
      this.intervalId = setInterval(() => {
        this.fetchDashboard();
      }, 600000);
    })
  }

  fetchDashboard = () => {
    const queryParams = queryString.parse(this.props.location.search);

    const params = {
      asset_id: queryParams.asset_id
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}dashboard/${this.state.token}?${HelperFunctions.convertObjToQueryParameters(params)}`)
      .then(res => {
        this.setState({ loading: false, dashboard: res.data });
      })
      .catch(error => {
        this.setState({ loading: false, dashboard: null, notFound: true });
      });
  };

  renderNotFound = () => (
    <div className={styles['not-found']}>
      404 - Tavla kunde inte hittas
    </div>
  )

  renderDashboard = () => {
    if (this.state.loading) return null;
    if (this.state.notFound) return this.renderNotFound();

    switch (this.state.dashboard.type) {
      case 'work_orders':
        return <WorkOrders dashboard={this.state.dashboard} />
      case 'operational_maintenances':
        return <OperationalMaintenances dashboard={this.state.dashboard} />
    }
  }

  render() {
    return (
      <div>
        {this.renderDashboard()}
      </div>
    )
  }
}

export default withRouter(Dashboard);
