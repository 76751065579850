import moment from 'moment';
import 'moment/locale/sv';
/*
  ------------------
    Swedish
  ------------------
*/
const initializeSwedishTranslation = () => {
  moment.locale('sv', {
    calendar: {
      lastDay: '[igår]',
      sameDay: '[idag]',
      nextDay: '[imorgon]',
      lastWeek: 'dddd [förra veckan]',
      nextWeek: 'På dddd',
      sameElse: 'L',
    },
    relativeTime: {
      past: '%s sedan',
    },
    longDateFormat: {
      LT: '[kl.] HH:mm',
    },
    months: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    weekdays: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
    weekdaysShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
    weekdaysMin: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
  });
};

const initializeEnglishranslation = () => {
  moment.locale('en');
};

const init = () => {
  initializeSwedishTranslation();
  initializeEnglishranslation();
};

export default {
  init,
};
