import React, { Component } from 'react';
import LeftContent from './LeftContent';
import RightContent from './RightContent';
import styles from './style.module.scss';

export default class OperationalMaintenances extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['left']}>
          {<LeftContent dashboard={this.props.dashboard} />}
        </div>
        <div className={styles['right']}>
          <RightContent dashboard={this.props.dashboard} />
        </div>
      </div>
    )
  }
}
