/*
  ------------------
    Swedish
  ------------------
*/

const convertObjToQueryParameters = obj => {
  return getPairs(obj)
    .map(([[key0, ...keysRest], value]) => `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`)
    .join('&');
};


const getPairs = (obj = {}, keys = []) => {
  return Object.entries(obj)
    .filter(item => {
      return item[1] != null;
    })
    .reduce((pairs, [key, value]) => {
      if (typeof value === 'object') {
        pairs.push(...getPairs(value, [...keys, key]));
      } else {
        pairs.push([[...keys, key], value]);
      }
      return pairs;
    }, []);
};

export default {
  convertObjToQueryParameters,
};
