import React, { Component } from 'react';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import styles from './style.module.scss';

export default class ThisWeekChart extends Component {
  chartOptions = () => ({
    legend: false,
    maintainAspectRatio: false,
    tooltips: false,
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 14,
            fontFamily: "'Open Sans', 'Helvetica Neue'",
            fontColor: '#515151',
            padding: 13,
            fontStyle: '600',
            suggestedMax: 10,
            beginAtZero: true,
            maxTicksLimit: 4,
            callback: (value, index, values) => (`${value} st`)
          },
          gridLines: {
            lineWidth: 0,
            zeroLineColor: '#E1E1E1',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 14,
            fontFamily: "'Open Sans', 'Helvetica Neue'",
            fontColor: '#515151',
            fontStyle: '600',
          },
          gridLines: {
            lineWidth: 0,
            zeroLineColor: '#E1E1E1',
          },
        },
      ],
    }
  });

  buildDueDataset = () => {
    const db = this.props.dashboard.due;
    const today = moment();

    const colors = [
      moment().weekday(0).diff(today) < 0 ? '#C55050' : '#4C9AC8',
      moment().weekday(1).diff(today) < 0 ? '#C55050' : '#4C9AC8',
      moment().weekday(2).diff(today) < 0 ? '#C55050' : '#4C9AC8',
      moment().weekday(3).diff(today) < 0 ? '#C55050' : '#4C9AC8',
      moment().weekday(4).diff(today) < 0 ? '#C55050' : '#4C9AC8',
      moment().weekday(5).diff(today) < 0 ? '#C55050' : '#4C9AC8',
      moment().weekday(6).diff(today) < 0 ? '#C55050' : '#4C9AC8',
    ]

    return {
      label: 'Att utföra',
      data: [db.monday, db.tuesday, db.wednesday, db.thursday, db.friday, db.saturday, db.sunday],
      backgroundColor: colors,
      minBarLength: 2,
    }
  }

  buildCompletedDataset = () => {
    const db = this.props.dashboard.completed;

    return {
      label: 'Färdigställda',
      data: [db.monday, db.tuesday, db.wednesday, db.thursday, db.friday, db.saturday, db.sunday],
      backgroundColor: '#2BAF2D',
      minBarLength: 2,
    }
  }

  renderLegends = () => (
    <div className={styles['legends']}>
      <div className={styles['legend']}>
        <div className={[styles['dot'], styles['red']].join(' ')} />
        Försenade
      </div>
      <div className={styles['legend']}>
        <div className={[styles['dot'], styles['blue']].join(' ')} />
        Att göra
      </div>
      <div className={styles['legend']}>
        <div className={[styles['dot'], styles['green']].join(' ')} />
        Färdigställda
      </div>
    </div>
  )

  render() {
    const labels = [
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
      "Söndag",
    ];

    return (
      <div className={styles['container']}>
        {this.renderLegends()}
        <div className={styles['chart']}>
          <Bar
            options={this.chartOptions()}
            data={{
              labels: labels,
              datasets: [this.buildDueDataset(), this.buildCompletedDataset()]
            }}
          />
        </div>
      </div>
    );
  }
}