import React, { Component } from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import styles from './style.module.scss';

export default class TimelineChart extends Component {
  chartOptions = () => ({
    legend: false,
    maintainAspectRatio: false,
    tooltips: false,
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 14,
            fontFamily: "'Open Sans', 'Helvetica Neue'",
            fontColor: '#515151',
            padding: 13,
            fontStyle: '600',
            suggestedMax: 10,
            beginAtZero: true,
            maxTicksLimit: 4,
            callback: (value, index, values) => (`${value} st`)
          },
          gridLines: {
            lineWidth: 0,
            zeroLineColor: '#E1E1E1',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 14,
            fontFamily: "'Open Sans', 'Helvetica Neue'",
            fontColor: '#515151',
            fontStyle: '600',
          },
          gridLines: {
            lineWidth: 0,
            zeroLineColor: '#E1E1E1',
          },
        },
      ],
    }
  });

  renderLegends = () => (
    <div className={styles['legends']}>
      <div className={styles['legend']}>
        <div className={[styles['dot'], styles['green']].join(' ')} />
        Förebyggande
      </div>
      <div className={styles['legend']}>
        <div className={[styles['dot'], styles['red']].join(' ')} />
        Avhjälpande
      </div>
    </div>
  )

  getTimelineArray = () => {
    let start = moment().add(-20 , 'w').day(1);
    let end = moment().add().day(1);

    var arr = [];
    // Get "next" monday
    let tmp = start.clone().day(1);
    if( tmp.isAfter(start, 'd') ){
      arr.push(tmp.format('YYYY-MM-DD'));
    }
    while( tmp.isBefore(end) ){
      tmp.add(7, 'days');
      arr.push(tmp.format('YYYY-MM-DD'));
    }
    return arr;
  }

  render() {
    console.log(this.props.dashboard);
    const week_dates = this.getTimelineArray();

    const labels = week_dates.map(week_date => `v${moment(week_date, 'YYYY-MM-DD').isoWeek()}`);

    return (
      <div className={styles['container']}>
        <div className={styles['title']}>Färdigställda arbetsordrar</div>
        {this.renderLegends()}
        <div className={styles['chart']}>
          <Line
            options={this.chartOptions()}
            datasetKeyProvider={(a) => a.key}
            data={{
              labels: labels,
              datasets: [{
                key: 'preventive',
                data: week_dates.map(week_date => this.props.dashboard.timeline.preventive[week_date] || 0),
                borderColor: '#2BAF2D',
                backgroundColor: 'rgba(0, 0, 0, 0)',
              }, 
              {
                key: 'corrective',
                data: week_dates.map(week_date => this.props.dashboard.timeline.corrective[week_date] || 0),
                borderColor: '#C55050',
                backgroundColor: 'rgba(0, 0, 0, 0)',
              }]
            }}
          />
        </div>
      </div>
    );
  }
}