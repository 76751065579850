import React, { Component } from 'react';
import moment from 'moment';
import ThisWeekChart from './ThisWeekChart';
import LogoSvg from 'assets/images/logo.svg';
import { Icon } from 'views/components';
import styles from './style.module.scss';

export default class LeftContent extends Component {
  renderTitle = () => {
    const week = moment().isoWeek();

    return (
      <div className={styles['title-container']}>
        <div className={styles['title']}>
          <div>
            <span>Operatörsunderhåll</span>
            <span className={styles['week']}> - Vecka {week}</span>
          </div>
          <div className={styles['asset']}>
            {this.props.dashboard.asset_path.map((asset, index) => {
              return (
                <>
                  <span>{asset}</span>
                  {this.props.dashboard.asset_path.length - 1 === index ? null : (
                    <span style={{ marginLeft: 8, marginRight: 8 }}>
                      <Icon type="arrow-alt-right" />
                    </span>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <div className={styles['logo']}>
          <img src={LogoSvg} alt="logo" />
        </div>
      </div>
    );
  };

  renderGraphs = () => (
    <div className={styles['graphs-container']}>
      <ThisWeekChart dashboard={this.props.dashboard} />
    </div>
  );

  render() {
    return (
      <div className={styles['content']}>
        {this.renderTitle()}
        {this.renderGraphs()}
      </div>
    );
  }
}
