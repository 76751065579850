import React, { Component } from 'react';
import styles from './style.module.scss';

export default class RightContent extends Component {
  renderDueThisWeek = () => (
    <div className={styles['field']}>
      <div className={styles['label']}>Att utföra i veckan</div>
      <div className={styles['value']}>{this.props.dashboard.due_this_week} st</div>
    </div>
  )

  renderCompletedThisWeek = () => (
    <div className={styles['field']}>
      <div className={styles['label']}>Färdigställda i veckan</div>
      <div className={styles['value']}>{this.props.dashboard.completed_this_week} st</div>
    </div>
  )

  renderOverdueThisWeek = () => {
    let classNames = [styles['value']]
    if (this.props.dashboard.overdue_this_week > 0) {
      classNames = [...classNames, styles['red']]
    }

    return (
      <div className={styles['field']}>
        <div className={styles['label']}>Försenade i veckan</div>
        <div className={classNames.join(' ')}>{this.props.dashboard.overdue_this_week} st</div>
      </div>
    )
  }

  renderDifference = (value) => {
    let classNames = [styles['difference']]
    let symbol = "";

    if (value > 0) {
      classNames = [...classNames, styles['red']]
    } else {
      classNames = [...classNames, styles['green']]
    }

    if (value >= 0) {
      symbol = "+"
    }

    return (
      <div className={classNames.join(' ')}>
        <div className={styles['dot']} />
        <span>{symbol}{value} st i veckan</span>
      </div>
    );
  }

  renderOverdueTotal = () => {
    let classNames = [styles['value']]
    if (this.props.dashboard.overdue_total > 0) {
      classNames = [...classNames, styles['red']]
    }

    return (
      <div className={styles['field']}>
        <div className={styles['label']}>Totalt försenade</div>
        <div className={classNames.join(' ')}>{this.props.dashboard.overdue_total} st</div>
        {this.renderDifference(this.props.dashboard.overdue_total - this.props.dashboard.overdue_total_last_week)}
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderDueThisWeek()}
        {this.renderCompletedThisWeek()}
        {this.renderOverdueThisWeek()}
      </>
    )
  }
}
