import React, { Component } from 'react';
import styles from './style.module.scss';

export default class RightContent extends Component {
  renderDueThisWeek = () => (
    <div className={styles['field']}>
      <div className={styles['label']}>Att utföra i veckan</div>
      <div className={styles['rows']}>
        <div className={styles['value-row']}>
          <div className={styles['value-label']}>Förebyggande</div>
          <div className={styles['value']}>{this.props.dashboard.due_this_week.preventive} st</div>
        </div>
        <div className={styles['value-row']}>
          <div className={styles['value-label']}>Avhjälpande</div>
          <div className={styles['value']}>{this.props.dashboard.due_this_week.corrective} st</div>
        </div>
      </div>
    </div>
  )

  renderCompletedThisWeek = () => (
    <div className={styles['field']}>
      <div className={styles['label']}>Färdigställda i veckan</div>
      <div className={styles['rows']}>
        <div className={styles['value-row']}>
          <div className={styles['value-label']}>Förebyggande</div>
          <div className={styles['value']}>{this.props.dashboard.completed_this_week.preventive} st</div>
        </div>
        <div className={styles['value-row']}>
          <div className={styles['value-label']}>Avhjälpande</div>
          <div className={styles['value']}>{this.props.dashboard.completed_this_week.corrective} st</div>
        </div>
      </div>
    </div>
  )

  renderOverdueThisWeek = () => {
    let preventiveStyles = [styles['value']]
    if(this.props.dashboard.overdue_this_week.preventive > 0) {
      preventiveStyles = [...preventiveStyles, styles['red']]
    }
    
    let correctiveStyles = [styles['value']]
    if(this.props.dashboard.overdue_this_week.corrective > 0) {
      correctiveStyles = [...correctiveStyles, styles['red']]
    }
    
    return (
      <div className={styles['field']}>
        <div className={styles['label']}>Försenade i veckan</div>
        <div className={styles['rows']}>
          <div className={styles['value-row']}>
            <div className={styles['value-label']}>Förebyggande</div>
            <div className={preventiveStyles.join(' ')}>{this.props.dashboard.overdue_this_week.preventive} st</div>
          </div>
          <div className={styles['value-row']}>
            <div className={styles['value-label']}>Avhjälpande</div>
            <div className={correctiveStyles.join(' ')}>{this.props.dashboard.overdue_this_week.corrective} st</div>
          </div>
        </div>
      </div>
    )
  }

  renderDifference = (value) => {
    let classNames = [styles['difference']]
    let symbol = "";

    if (value > 0) {
      classNames = [...classNames, styles['red']]
    } else {
      classNames = [...classNames, styles['green']]
    }

    if (value >= 0) {
      symbol = "+"
    }

    return (
      <div className={classNames.join(' ')}>
        <div className={styles['dot']} />
        <span>{symbol}{value} st i veckan</span>
      </div>
    );
  }

  renderOverdueTotal = () => {
    let preventiveStyles = [styles['value']]
    if(this.props.dashboard.overdue_total.preventive > 0) {
      preventiveStyles = [...preventiveStyles, styles['red']]
    }
    
    let correctiveStyles = [styles['value']]
    if(this.props.dashboard.overdue_total.corrective > 0) {
      correctiveStyles = [...correctiveStyles, styles['red']]
    }
    
    return (
      <div className={styles['field']}>
        <div className={styles['label']}>Totalt försenade</div>
        <div className={styles['rows']}>
          <div>
            <div className={styles['value-row']}>
              <div className={styles['value-label']}>Förebyggande</div>
              <div className={preventiveStyles.join(' ')}>{this.props.dashboard.overdue_total.preventive} st</div>
            </div>
            {this.renderDifference(this.props.dashboard.overdue_total.preventive - this.props.dashboard.overdue_total_last_week.preventive)}
          </div>
          <div>
            <div className={styles['value-row']}>
              <div className={styles['value-label']}>Avhjälpande</div>
              <div className={correctiveStyles.join(' ')}>{this.props.dashboard.overdue_total.corrective} st</div>
            </div>
            {this.renderDifference(this.props.dashboard.overdue_total.corrective - this.props.dashboard.overdue_total_last_week.corrective)}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderDueThisWeek()}
        {this.renderCompletedThisWeek()}
        {this.renderOverdueThisWeek()}
        <div className={styles['separator']} />
        {this.renderOverdueTotal()}
      </>
    )
  }
}
